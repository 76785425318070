<template >
  <DxPopup :ref="popupPrintRefName"
        height="auto"
        width="900px"
        :drag-enabled="false"
        :hideOnOutsideClick="true"
        :showTitle="true"
        title="의약품 안전카드 인쇄/전송">
        <div class="content-block dx-card responsive-paddings">
          <div class="dx-field">
          <div class="dx-field-label">약물 카드</div>
          <div class="dx-field-value">
            <DxSelectBox
              :dataSource="cardList"
              v-model:value="selectedCard"
              display-expr="cardName"
              value-expr="recId"
              @value-changed="onValueChangedCard"/>
          </div>
          </div>
        </div>
        <div class="content-block dx-card responsive-paddings">
          <DxForm
            :ref="formRefName"
            label-location="top"
            :form-data="formData">
              <DxSimpleItem data-field="d1CausativeDrug"
                                editor-type="dxTextBox">
                                <DxLabel text="원인약물"/>   
                                <DxRequiredRule/>
                                <DxPatternRule
                                      pattern="^.{1,500}$"
                                      message="500자 까지 등록 가능"/>                                                    
              </DxSimpleItem>
              <DxSimpleItem data-field="d1Symptom"
                                editor-type="dxTextBox">
                                <DxLabel text="증상"/>  
                                <DxRequiredRule/> 
                                <DxPatternRule
                                      pattern="^.{1,500}$"
                                      message="500자 까지 등록 가능"/>                                                    
              </DxSimpleItem>
              <DxSimpleItem data-field="d2CiGroup"
                                editor-type="dxTextBox">
                                <DxLabel text="금기 성분(군)"/>   
                                <DxPatternRule
                                      pattern="^.{0,500}$"
                                      message="500자 까지 등록 가능"/>                                                    
              </DxSimpleItem>
              <DxSimpleItem data-field="d2CrhiGroup"
                                editor-type="dxTextBox">
                                <DxLabel text="주의 성분(군)"/>   
                                <DxPatternRule
                                      pattern="^.{0,500}$"
                                      message="500자 까지 등록 가능"/>                                                    
              </DxSimpleItem>
              <DxSimpleItem data-field="d2OaiGroup"
                                editor-type="dxTextBox">
                                <DxLabel text="복용가능 성분(군)"/>   
                                <DxPatternRule
                                      pattern="^.{0,500}$"
                                      message="500자 까지 등록 가능"/>                                                    
              </DxSimpleItem>
              <DxSimpleItem data-field="d2ExpertOpinion"
                                editor-type="dxTextBox">
                                <DxLabel text="전처치(전문가 의견)"/>   
                                <DxPatternRule
                                      pattern="^(.|\n|\r){1,1500}$"
                                      message="1500 까지 등록 가능"/>                                                    
              </DxSimpleItem>      
              <DxGroupItem :colCount="3">
                <DxButtonItem>
                  <DxButtonOptions
                      width="100%"
                      type="default"
                      text="미리보기"
                      icon='image'
                      stylingMode="outlined"
                      @click="previewCard">
                  </DxButtonOptions>
                </DxButtonItem>
                <DxButtonItem>
                  <DxButtonOptions
                      width="100%"
                      type="default"
                      text="인쇄"
                      icon='print'
                      stylingMode="outlined"
                      @click="printCard">
                  </DxButtonOptions>
                </DxButtonItem>  
                              <DxButtonItem>
                  <DxButtonOptions
                      width="100%"
                      type="default"
                      text="전송"
                      icon='message'
                      stylingMode="outlined"
                      :disabled="disabledSend"
                      @click="sendCard">
                  </DxButtonOptions>
                </DxButtonItem>       
              </DxGroupItem>                                                 
          </DxForm>
        </div>
        <br>
      </DxPopup>

      <DxPopup :ref="popupPreviewCard"
        height="auto"
        width="auto"
        :drag-enabled="false"
        :hideOnOutsideClick="true"
        :showTitle="true"
        title="의약품 안전카드 미리보기(카카오톡 전송용)">
        <div class="content-block dx-card responsive-paddings">
          <div class="previewNotice">카카오톡 전송용 미리 보기입니다. 실제 카드 인쇄 시에는 일부 차이가 있을 수 있습니다.</div>
          <img class="cardImageDesktop" :src="previewCardURL" />
        </div>
      </DxPopup>
</template>

<script>
 import DxPopup from "devextreme-vue/popup";        
 import DxForm, {
        DxLabel,
        DxSimpleItem,
        DxPatternRule,
        DxButtonItem,
        DxButtonOptions,
        DxGroupItem,
        DxRequiredRule
    } from "devextreme-vue/form";
  import DxSelectBox from 'devextreme-vue/select-box';
  import dsu from '../../utils/dataSourceUtils';
  import fn from '../../utils/function';
  import axios from 'axios';
  import customProtocolCheck from "custom-protocol-check";
  
  export default {
    components: {
        DxPopup,
        DxForm, 
        DxLabel,
        DxSimpleItem,
        DxPatternRule,
        DxButtonItem,
        DxButtonOptions,
        DxGroupItem,
        DxRequiredRule,
        DxSelectBox,
    },
    data() {
      return {
        popupPrintRefName: 'popupPrint',
        cardList: [],
        selectedCard: '',
        formRefName: 'form',
        formData: {},
        popupPreviewCard: 'popupPreviewCard',
        previewCardURL: '',
        targetList: [],
        printMethod: '',
        ptInfo: {ptName: '', ptNumber: '', ptBirthDay: '', ptTel: ''},
        disabledSend: false
      };
    },
    methods:{
      show(params, ptInfo) {
        this.targetList = params.targetRecId;
        this.printMethod = params.printMethod;
        if (this.printMethod == 'TEMP') {
          this.ptInfo = ptInfo;
          this.disabledSend = true;
        }
        dsu.getAxiosOBJGet('/api/IPRT/selectCardInfoBefore', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.$refs[this.popupPrintRefName].instance.show();
            this.cardList = response.data.map(item => ({
                                recId: item.recId,
                                cardName: item.cardName,
                                d1CausativeDrug: item.d1CausativeDrug,
                                d1Symptom: item.d1Symptom,
                                d2CiGroup: item.d2CiGroup,
                                d2CrhiGroup: item.d2CrhiGroup,
                                d2OaiGroup: item.d2OaiGroup,
                                d2ExpertOpinion: item.d2ExpertOpinion
                            }));
            this.selectedCard = this.cardList[0].recId;
            this.onValueChangedCard({value: this.cardList[0].recId});
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      async previewCard() { 
        var params = JSON.parse(JSON.stringify(this.formData));
        params.printMethod = this.printMethod;
        if (params.printMethod == 'TEMP') {
          params.ptName = this.ptInfo.ptName;
          params.ptNumber = this.ptInfo.ptNumber;
          params.ptBirthDay = this.ptInfo.ptBirthDay;
        }
        dsu.setDefaultParams(params); 
        const response = await axios({
            url: '/api/ADRI/downloadPreviewCard',
            method: 'POST',
            data: params,
            headers: dsu.getHeaders(),
            responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        this.previewCardURL = window.URL.createObjectURL(blob);
        this.$refs[this.popupPreviewCard].instance.show();
      },
      onValueChangedCard(e) {
        var selectedIdx = this.cardList.findIndex(data => data.recId == e.value);
        this.formData = this.cardList[selectedIdx];
      },
      printCard(e){
        var params = {};  
        e.component.option('disabled', true);
        if(navigator.userAgent.toUpperCase().indexOf("WINDOWS") < 0 ){
          fn.notifyError("Windows 에서만 인쇄 가능");
          e.component.option('disabled', false);
          return;
        }else{
          params.targetRecId = this.targetList;
          params.cardInfo = JSON.stringify(this.cardList);
          params.printMethod = this.printMethod;
          dsu.getAxiosOBJPost('/api/ADRI/insertPrintHst', params).then(res => {
              let response = res.data;
            if (response.result == 'SUCCESS') {
                let url = 'myadr://' + response.data + "," + this.printMethod + "," + encodeURIComponent(this.ptInfo.ptName) + "," + this.ptInfo.ptNumber + "," + this.ptInfo.ptBirthDay + "," + this.ptInfo.ptTel;
                customProtocolCheck(url,
                  () => {
                      e.component.option('disabled', false);
                      fn.alert("MyADR Printer 가 설치되지 않았습니다.", "알람");
                  },
                  () => {}, 
                  3000
                );
              } else {
                fn.notifyError(response.msg);
              }
              e.component.option('disabled', false);
          });
        }
      },
      sendCard(e){
        var params = {};
        e.component.option('disabled', true);
          params.targetRecId = this.targetList
          params.cardInfo = JSON.stringify(this.cardList);
          dsu.getAxiosOBJPost('/api/ADRI/insertSendHst', params).then(res => {      
            let response = res.data;
            if (response.result == 'SUCCESS') {
              fn.notifySuccess(response.msg);
            } else {
              fn.notifyError(response.msg);
            }
            e.component.option('disabled', false);
        });
      },
    }
  };
</script>

<style scoped>
  .cardImageDesktop {
    width: 600px;
  }

  .previewNotice{
    width: 600px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    text-align: left;
    font-weight: bold;
    color: red;
    font-size: 20px;
  }
</style>