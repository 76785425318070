<template>
  <DxDataGrid
      :data-source="targetList"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true">
      <DxScrolling mode="infinite"/>
      <DxSelection mode="none"/>
      <DxColumn data-field="kdCode" caption="제품 코드"/>
      <DxColumn data-field="kdName" caption="제품 명"/>
    </DxDataGrid>
</template>
<script>

import {DxDataGrid,
        DxSelection,
        DxScrolling,
        DxColumn
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn
  },
  props: {
    kdData: String
  },
  mounted() {
    this.targetList = JSON.parse(this.kdData);
  },
  data() {
    return {
      targetList: []
    };
  }
};
</script>

<style scoped>
</style>