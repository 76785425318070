<template>
  <DxDataGrid
      :data-source="targetList"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true">
      <DxScrolling mode="infinite"/>
      <DxSelection mode="none"/>
      <DxColumn data-field="causativeDrug" caption="원인약물" cell-template="drug-cell-template"/>
      <DxColumn data-field="symptom" caption="부작용 증상" cell-template="symptom-cell-template"/>
    
        <template #drug-cell-template="{data}">
            <GridDrug :drugData="JSON.stringify(data.value)"/>
        </template>      
      P
        <template #symptom-cell-template="{data}">
            <GridSymptom :symptomData="JSON.stringify(data.value)"/>
        </template>        
        
        
    </DxDataGrid>
</template>
<script>

import {DxDataGrid,
        DxSelection,
        DxScrolling,
        DxColumn
} from 'devextreme-vue/data-grid';
import GridDrug from "../_common/GRID_DRUG";
import GridSymptom from "../_common/GRID_SYMPTOM";

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
    GridDrug,
    GridSymptom
  },
  props: {
    sideEffectsData: String
  },
  mounted() {
    this.targetList = JSON.parse(this.sideEffectsData);
  },
  data() {
    return {
      targetList: []
    };
  }
};
</script>

<style scoped>
</style>