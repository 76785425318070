<template>
  <div class="content-block dx-card responsive-paddings">
      <DxLoadPanel
      :position="position"
      :visible="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
      message="피드백 처리 중...."
      shading-color="rgba(0,0,0,0.4)"
    />
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @rowDblClick="onRowDblClick"
      @row-removing="onRowRemoving">
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxEditing :allow-updating="false" :allow-adding="false" :allow-deleting="true"/>
      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="reqId"
        caption="Req ID"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="reqDate"
        caption="요청 일"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="status"
        caption="상태"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="age"
        data-type="number"
        caption="나이"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="gender"
        caption="성별"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="comorbidities"
        caption="동반질환"
        :header-filter="{ allowSearch: true }"
        cell-template="diagnosis-cell-template"
      />
      <DxColumn
        data-field="sideEffects"
        caption="부작용 정보"
        :header-filter="{ allowSearch: true }"
        cell-template="sideEffects-cell-template"
      />
      <DxColumn
        data-field="currentMedication"
        caption="현재(=기) 복용 약제"
        :header-filter="{ allowSearch: true }"
        cell-template="kd-cell-template"
      />
      <DxColumn
        data-field="newPrescriptions"
        caption="신규 처방 약제"
        :header-filter="{ allowSearch: true }"
        cell-template="kd-cell-template"
      />
      
      <template #diagnosis-cell-template="{data}">
        <GridDiagnosis :diagnosisData="data.value"/>
      </template>    
      <template #sideEffects-cell-template="{data}">
        <GridSideEffects :sideEffectsData="data.value"/>
      </template>    
      <template #kd-cell-template="{data}">
        <GridKd :kdData="data.value"/>
      </template>    
    </DxDataGrid>
    <DxPopup id="popup"
          :ref="popupRefName"
          height="95%"
          width="95%"
          :drag-enabled="false"
          :hideOnOutsideClick="true"
          :showTitle="true"
          :showCloseButton="true"
          title="복약지도문 정보">
      <div class="popup-content">
        <DxList
          :ref="listRefName"
          :data-source="selectedMemory"
          @itemRendered="onItemRendered"
          class="list-container">
          <template #item="{ data: item }">
            <div class="memory">
              <div v-if="item.type == 'SYSTEM'" class="system">
                <div class="type">{{ item.type }}</div>
                <div class="text">{{ item.text }}</div>
              </div>
              <div v-if="item.type == 'USER'" class="user">
                <div class="type">{{ item.type }}</div>
                <div class="text">{{ item.contents[0].text }}</div>
              </div>
              <div v-if="item.type == 'AI'" class="ai">
                <div class="type">{{ item.type }}</div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </template>
        </DxList>
        <DxTextBox v-model="feedbackText"
                   class="textbox-container"   
                   placeholder="피드백을 입력하세요"  
                   @enterKey="onFeedbackRequest()"
                   :buttons="[{ name: 'feedback', location: 'after' , options: {text: '전송', onClick: onFeedbackRequest}}]"/>
      </div>
    </DxPopup>
  </div>
</template>

<script>
  import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxSearchPanel,
    DxEditing
  } from "devextreme-vue/data-grid";
  import DxList from 'devextreme-vue/list';
  import DxPopup from "devextreme-vue/popup";      
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  import GridDiagnosis from "../_common/GRID_DIAGNOSIS";
  import GridSideEffects from "../_common/GRID_SIDE_EFFECTS";
  import GridKd from "../_common/GRID_KD";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxEditing,
      DxPopup,
      GridDiagnosis,
      GridKd,
      GridSideEffects,
      DxList,
      DxTextBox,
      DxLoadPanel
    },
    data() {
      return {
        gridRefName: "grid",
        popupRefName: 'popup',
        listRefName: 'list',
        selectedReqId: '',
        selectedMemory: [],
        feedbackText: '',
        loadingPosition: { of: '#popup' },
        loadingVisible: false
      };
    },
    mounted() {
      this.search();  
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      popup: function () { return this.$refs[this.popupRefName].instance; },
      list: function () { return this.$refs[this.listRefName].instance; },
      sendButton: function () {return this.$refs[this.sendButtonRefName].instance;},
    },
    methods: {
      search() {
        this.popup.hide();
        this.grid.beginCustomLoading("데이터 조회 중...");
        dsu.getAxiosOBJGet('/api/MMMG/selectMGDataIF').then(res=>{
          this.grid.endCustomLoading();
          let response = res.data;
          if(response.result == 'SUCCESS'){
            var data = response.data;
            this.setGridData(data);
            this.$emit("refreshMenu");
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      setGridData(data){
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'reqId');
        grid.option('dataSource', data);
      },
      onRowDblClick(e) {
        this.selectedReqId = e.data.reqId;
        this.refreshMemory();
      },
      refreshMemory() {
        var params = {};
        params.reqId = this.selectedReqId;
        dsu.getAxiosOBJGet('/api/MMMG/selectMGDataIF', params).then(res => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.selectedMemory = JSON.parse(response.data[0].memory);
            this.feedbackText = '';
            this.popup.show();
            setTimeout(() => { this.list.scrollToItem(this.selectedMemory.length - 1)  }, 500);
          }else {
            fn.notifyError(response.msg);  
          }
        });
      },
      onRowRemoving(e) {
        e.cancel = true;
        dsu.getAxiosOBJPost('/api/MMMG/deleteMGDataIF', e.data).then(res => {
          this.search();
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);  
            }
        });
      },
      onFeedbackRequest() {
        if(!this.selectedReqId){
          return;
        }

        if(!this.feedbackText.trim()){
          fn.notifyError('피드백을 입력하세요.');
          return;
        }
        this.loadingVisible = true;

        var params = {}
        params.reqId = this.selectedReqId;
        params.feedback = this.feedbackText;
        dsu.getAxiosOBJPost('/api/MMMG/sendFeedbackMG', params).then(res => {
          this.loadingVisible = false;
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.refreshMemory();
          }else {
            fn.notifyError(response.msg);  
          }
        });
      },
      onItemRendered() {
        this.list.scrollToItem(this.selectedMemory.length - 1);
      }
    }
  };
</script>

<style lang="scss">
  #grid {
    max-height: calc(100vh - 210px);
  }
  .memory{
    .system, .user, .ai {
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      white-space: pre-wrap; /* 자동 줄바꿈을 처리하는 CSS 속성 */
      text-align: left;
    }
    .system {
      background-color: #e0e0e0;
      margin-right: 10%;
      margin-left: 20px;
    }
    .user {
      background-color: #cce5ff;
      margin-left: 10%;
      margin-right: 20px; 
    }
    .ai {
      background-color: #d4edda;
      margin-left: 20px;
      margin-right: 10%;
    }
  }
  .type{
    font-weight: bold;
    margin-bottom: 5px;
  }

  .popup-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-container {
  flex-grow: 1;
}

.textbox-container {
  flex-shrink: 0; /* 텍스트박스가 고정된 위치에 있게 설정 */
  padding: 10px;
  border-top: 1px solid #ccc; /* 상단에 구분선 추가 */
}
</style>