<template>
  <div class="dxform-container">
    <DxScrollView class="scrollable-content">
      <DxForm
        id="ADRForm"
        class="adrDataForm"
        :ref="ADRFormRefName"
        label-location="top"
        :disabled="loading"
        :form-data="dataSource"
      >
        <DxColCountByScreen :xs="4" :sm="4" :md="4" :lg="4" />
        <DxGroupItem :colSpan="4">
          <DxLabel template="labelGPPT" text="등록 희망자" />
          <DxGroupItem :colCount="4"
                      css-class="form-group">
            <DxSimpleItem data-field="ptName" editor-type="dxTextBox">
              <DxLabel text="성명" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,15}$" message="15자 까지 등록 가능" />
            </DxSimpleItem>
            
            <DxSimpleItem
              data-field="ptNumber"
              editor-type="dxTextBox"
              :editor-options="{onEnterKey: getPTInfo}"
            >
              <DxLabel text="병원등록번호" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="ptRrn"
              editor-type="dxTextBox"
              :editor-options="{ mask: '00000000' }"
            >
              <DxLabel text="생년월일" />
              <DxRequiredRule />
              <DxPatternRule
                pattern="^.{8}$"
                message="생년월일은 8자로 등록 필요"
              />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="ptTel"
              editor-type="dxTextBox"
              :editor-options="{ mask: '000-0000-0000' }"
            >
              <DxLabel text="휴대폰 번호" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,20}$" message="20자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="ptEmail" editor-type="dxTextBox">
              <DxLabel text="전자메일" />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
              <DxEmailRule message="이메일 형식 오류" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="ptAddress"
              editor-type="dxTextBox"
              :colSpan="3"
            >
              <DxLabel text="주소" />
              <DxPatternRule
                pattern="^(.|\n|\r){1,200}$"
                message="200자 까지 등록 가능"
              />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>

        <DxEmptyItem :colSpan="4"/>
        
        <DxGroupItem :colSpan="4" :visible="visibleAp">
          <DxLabel template="labelGPAP" text="신청인" />
          <DxGroupItem :colCount="4"
                      css-class="form-group">
            <DxSimpleItem data-field="apName" editor-type="dxTextBox">
              <DxLabel text="성명" />
              <DxPatternRule pattern="^.{1,15}$" message="15자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="apRelationship"
              editor-type="dxSelectBox"
              :editor-options="{
              items: ['배우자', '자녀', '부모', '형제/자매', '조부모', '손자/손녀', '기타'],
                showClearButton: true
              }"
            >
              <DxLabel text="관계" />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="apRrn"
              editor-type="dxTextBox"
              :editor-options="{ mask: '00000000' }"
            >
              <DxLabel text="생년월일" />
              <DxPatternRule
                pattern="^.{8}$"
                message="생년월일은 8자로 등록 필요"
              />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="apTel"
              editor-type="dxTextBox"
              :editor-options="{ mask: '000-0000-0000' }"
            >
              <DxLabel text="휴대폰 번호" />
              <DxPatternRule pattern="^.{1,20}$" message="20자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="apEmail" editor-type="dxTextBox">
              <DxLabel text="전자메일" />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
              <DxEmailRule message="이메일 형식 오류" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>  

        <DxGroupItem :colSpan="4"
                    :colCount="5">
        <DxEmptyItem :colSpan="2"/>
          <DxButtonItem>
            <DxButtonOptions
              width="100%"
              type="normal"
              icon="info"
              :text=btnToggleApName
              @click="toggleApName"/>    
          </DxButtonItem>
          <DxEmptyItem :colSpan="2"/>
        </DxGroupItem>
        
        <DxEmptyItem :colSpan="4"/>
        
        <DxGroupItem :colSpan="4">
          <DxLabel template="labelGPD1" text="약물이상사례(개별사례)" />
          <DxGroupItem/>

          <DxGroupItem :colCount="4"
                css-class="form-group"
                v-for="(item, index) in d1List"
                :key="'gD1' + (index + 1)"
                :visible="item.visible">
            <DxEmptyItem :colSpan="3"/>
            <DxButtonItem >
              <DxButtonOptions
                width="100%"
                type="normal"
                icon="trash"
                text="삭제"
                @click="removeD1(index)"/>    
            </DxButtonItem>

            <DxSimpleItem 
              :data-field="'d1List[' + index + '].d1OccurenceDate'"
              editor-type="dxDateBox"
              :editor-options="editorOptionsDateBox"
            >
              <DxLabel text="발생일"/>
              <DxRequiredRule />
            </DxSimpleItem>

            <DxSimpleItem
              :data-field="'d1List[' + index + '].d1Causality'"
              editor-type="dxSelectBox"
              :editor-options="{ items: causalityitems }"
            >
              <DxLabel text="인과성" />
              <DxRequiredRule />
            </DxSimpleItem>

            <DxSimpleItem
              :data-field="'d1List[' + index + '].d1Severity'"
              editor-type="dxSelectBox"
              :editor-options="{ items: ['Mild', 'Moderate', 'Severe'] }"
            >
              <DxLabel text="중증도" />
              <DxRequiredRule />
            </DxSimpleItem>

            <DxEmptyItem/>

            <DxSimpleItem :data-field="'d1List[' + index + '].d1CausativeDrug'">
              <template #default>
                <DxLabel text="원인약물" />
                <DxRequiredRule />
                <ModifyDrugAtc
                  title="원인약물, 약물이상사례(개별사례)"
                  :index="index"
                  @setDrugData="setD1CausativeDrug"
                  @getDrugData="getD1CausativeDrug"
                />
              </template>
            </DxSimpleItem>

            <DxSimpleItem :data-field="'d1List[' + index + '].d1Symptom'">
              <template #default>
                <DxLabel text="증상" />
                <DxRequiredRule />
                <ModifySymptom
                  title="증상, 약물이상사례(개별사례)"
                  :index="index"
                  @setSymptomData="setD1Symptom"
                  @getSymptomData="getD1Symptom"
                />
              </template>
            </DxSimpleItem>

            <DxSimpleItem
              :data-field="'d1List[' + index + '].d1CaseDescription'"
              editor-type="dxTextArea"
              :editor-options="editorOptionsTextArea"
              :colSpan="2"
            >
              <DxLabel text="사례_설명" />
              <DxRequiredRule />
              <DxPatternRule
                pattern="^(.|\n|\r){1,10000}$"
                message="10000자 까지 등록 가능"
              />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
        
        <DxGroupItem :colSpan="4"
                    :colCount="5">
        <DxEmptyItem :colSpan="2"/>
          <DxButtonItem>
            <DxButtonOptions
              width="100%"
              type="normal"
              icon="plus"
              text="개별사례 추가"
              @click="addD1"/>    
          </DxButtonItem>
          <DxEmptyItem :colSpan="2"/>
        </DxGroupItem>

        <DxGroupItem :colSpan="4">
          <DxLabel template="labelGPD2" text="약물이상사례(통합조정)" />
          <DxGroupItem :colCount="4"
                        css-class="form-group">
            <DxSimpleItem
              data-field="d2Template"
              editor-type="dxSelectBox"
              :editor-options="{
                items: d2TemplateList,
                searchEnabled: true,
                displayExpr: 'dspName',
                valueExpr: 'templateId',
                onValueChanged: onValueChangedD2TemplateId,
                showClearButton: 'true',
              }"
            >
              <DxLabel text="Template" />
            </DxSimpleItem>
            
            <DxSimpleItem data-field="d1ManagementNumber" editor-type="dxTextBox">
              <DxLabel text="원내_이상사례_관리번호" />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            
            <DxSimpleItem
              data-field="d2CurrentStatus"
              editor-type="dxSelectBox"
              :editor-options="{ items: ['Active', 'Inactive', 'Resolved'] }"
            >
              <DxLabel text="현재상태" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="d2DrugSideEffectsType"
              editor-type="dxSelectBox"
              :editor-options="{
                items: [
                  'Type A',
                  'Type B (allergy)',
                  'Type B (idiosyncrasy)',
                  'Type B (SCARs)',
                  'Type B (기타)',
                ],
              }"
            >
              <DxLabel text="유형" />
            </DxSimpleItem>

            <DxSimpleItem data-field="d2Symptom">
              <template #default>
                <DxLabel text="증상" />
                <ModifySymptom
                  title="증상, 약물이상사례(통합조정)"
                  @getSourceSymptomData="getD1SymptomAll"
                  @setSymptomData="setD2Symptom"
                  @getSymptomData="getD2Symptom"
                />
              </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="d2CiGroup">
              <template #default>
                <DxLabel template="labelRed" text="금기 성분(군)" />
                <ModifyDrugAtc
                  :ref="d2CiGroupRefName"
                  title="금기 성분(군), 약물이상사례(통합조정)"
                  @getSourceDrugData="getD1CausativeDrugAll"
                  @setDrugData="setD2CiGroup"
                  @getDrugData="getD2CiGroup"
                />
              </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="d2CrhiGroup">
              <template #default>
                <DxLabel template="labelOrange" text="주의 성분(군)" />
                <ModifyDrugAtc
                  :ref="d2CrhiGroupRefName"
                  title="주의 성분(군), 약물이상사례(통합조정)"
                  @setDrugData="setD2CrhiGroup"
                  @getDrugData="getD2CrhiGroup"
                />
              </template>
            </DxSimpleItem>

            <DxSimpleItem data-field="d2OaiGroup">
              <template #default>
                <DxLabel template="labelGreen" text="복용가능 성분(군)" />
                <ModifyDrugAtc
                  :ref="d2OaiGroupRefName"
                  title="복용가능 성분(군), 약물이상사례(통합조정)"
                  @setDrugData="setD2OaiGroup"
                  @getDrugData="getD2OaiGroup"
                />
              </template>
            </DxSimpleItem>
            <DxSimpleItem
              data-field="d2Causality"
              editor-type="dxSelectBox"
              :editor-options="{ items: causalityitems }"
            >
              <DxLabel text="인과성" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="d2Severity"
              editor-type="dxSelectBox"
              :editor-options="{ items: ['Mild', 'Moderate', 'Severe'] }"
            >
              <DxLabel text="중증도" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="d2ConfTest"
              editor-type="dxSelectBox"
              :colSpan="2"
              :editor-options="{
              items: ['Unconfirmed',
                      'Presummed (confirmed by past medical history)',
                      'Confirmed by skin test',
                      'Confirmed by drug specific IgE test',
                      'Confirmed by oral provocation test',
                      'Confirmed by IV challenge test',
                      'Confirmed by path test',
                      'Confirmed by test of type 4 hypersensitivity',
                      'Confirmed by test of lymphocyte transformation assay',
                      'Confirmed by other diagnostic tests']}">
              <DxLabel text="확진검사" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="d2ExpertOpinion"
              editor-type="dxTextArea"
              :editor-options="editorOptionsTextArea"
              :colSpan="2"
            >
              <DxLabel text="전문가의견" />
              <DxPatternRule
                pattern="^(.|\n|\r){1,1500}$"
                message="1500자 까지 등록 가능"
              />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
        
        <DxEmptyItem :colSpan="4"/>
        
        <DxGroupItem :colSpan="4">
          <DxLabel template="labelGPD3" text="의료기관" />
          <DxGroupItem :colCount="4"
                css-class="form-group">
            <DxSimpleItem
              data-field="d3Template"
              editor-type="dxSelectBox"
              :editor-options="{
                items: d3TemplateList,
                searchEnabled: true,
                displayExpr: 'dspName',
                valueExpr: 'templateId',
                onValueChanged: onValueChangedD3TemplateId,
                showClearButton: 'true',
              }"
            >
              <DxLabel text="Template" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="d3HospitalName"
              editor-type="dxTextBox"
            >
              <DxLabel text="의료기관명" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="d3HospitalCode"
              editor-type="dxTextBox"
            >
            <DxLabel text="의료기관코드" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,30}$" message="30자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="d3FinalEvaluator" editor-type="dxTextBox">
              <DxLabel text="최종평가자 직역" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="d3MedicalDepartment" editor-type="dxTextBox">
              <DxLabel text="최종평가자 전문과목" />
              <DxRequiredRule />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="d3EvaluationDepartment" editor-type="dxTextBox">
              <DxLabel text="평가부서" />
              <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem data-field="d3ManagerTel" editor-type="dxTextBox">
              <DxLabel text="평가부서 전화번호" />
              <DxPatternRule pattern="^.{1,20}$" message="20자 까지 등록 가능" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="d3Email"
              editor-type="dxTextBox"
              :editor-options="{ stylingMode: 'filled', mode: 'email' }">
              <dx-label text="평가부서 Email" />
              <dx-required-rule />
              <DxPatternRule pattern="^.{2,50}$" message="50자까지 입력 가능" />
              <dx-email-rule message="이메일 형식 오류" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
        
        <DxEmptyItem :colSpan="4"/>
      
        <DxGroupItem :colSpan="4">
            <DxLabel template="labelGPD4" text="기타" />
            <DxGroupItem :colCount="4"
                          css-class="form-group">
              <DxSimpleItem
                data-field="d4InsertTime"
                editor-type="dxDateBox"
                :editor-options="editorOptionsDateBoxD4"
              >
                <DxRequiredRule />
                <DxLabel text="최초작성일" />
              </DxSimpleItem>
              <DxSimpleItem
                data-field="d4UpdateTime"
                editor-type="dxDateBox"
                :editor-options="editorOptionsDateBoxD4"
              >
                <DxLabel text="서식수정일" />
              </DxSimpleItem>
              <DxSimpleItem
                v-if="isEditingMode"
                data-field="status"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: recStatus,
                  valueExpr: 'ID',
                  displayExpr: 'Name',
                }"
              >
                <DxRequiredRule />
                <DxLabel text="상태" />
              </DxSimpleItem>
            </DxGroupItem>
          </DxGroupItem>

        <template #labelRed="{ data }">
          <div class="labelRed">{{ data.text }}</div>
        </template>

        <template #labelOrange="{ data }">
          <div class="labelOrange">{{ data.text }}</div>
        </template>

        <template #labelGreen="{ data }">
          <div class="labelGreen">{{ data.text }}</div>
        </template>

        <template #labelGPPT="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconPT.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>

        <template #labelGPAP="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconAP.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>

        <template #labelGPD1="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconD1.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>

        <template #labelGPD2="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconD2.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>

        <template #labelGPD3="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconD3.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>

        <template #labelGPD4="{ data }">
          <div class="labelGroup">
            <img class="icon" src="../../assets/iconD4.png" />
            <div>{{ data.text }}</div>
          </div>
        </template>
        <DxTextArea />
      </DxForm>
    </DxScrollView>
    <div class="fixed-button">
      <DxForm
        id="ADRForm"
        :disabled="loading"
      >
        <DxColCountByScreen :xs="4" :sm="4" :md="4" :lg="4" />
        <DxGroupItem :colSpan="4" :colCount="getColCount">
            <DxEmptyItem :colSpan="2"/>
            <DxButtonItem>
              <DxButtonOptions
                width="100%"
                type="default"
                icon="save"
                styling-mode="outlined"
                :text="btnNameSave"
                @click="save">
              </DxButtonOptions>
            </DxButtonItem>
            <DxButtonItem v-if="showPrint">
              <DxButtonOptions
                width="100%"
                type="default"
                icon="print"
                text="인쇄"
                styling-mode="outlined"
                @click="printCard"
              >
              </DxButtonOptions>
            </DxButtonItem>
            <DxButtonItem v-if="visibleNewBtn">
              <DxButtonOptions
                width="100%"
                type="default"
                icon="doc"
                styling-mode="outlined"
                text="신규 등록"
                @click="reloadForm"
              >
              </DxButtonOptions>
            </DxButtonItem>

            <DxEmptyItem :colSpan="2"/>
          </DxGroupItem>
          </DxForm>

    </div>
</div>
  <PrintSendCard :ref="printSendCardRefName"/>
  <DxLoadPanel
    :position="{ of: '#ADRForm'}"
    :visible="loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    message="데이터 저장 중..."
    shading-color="rgba(0,0,0,0.4)"
  />
</template>

<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxPatternRule,
  DxEmailRule,
  DxRequiredRule,
  DxGroupItem,
  DxButtonItem,
  DxButtonOptions,
  DxEmptyItem,
} from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import { userStatus } from "../../utils/syscodedata";
import ModifySymptom from "./MODIFY_SYMPTOM";
import ModifyDrugAtc from "./MODIFY_DRUG_ATC";
import store from "../../store";
import PrintSendCard from "./PRINT_SEND_CARD";
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxScrollView from "devextreme-vue/scroll-view";

var today = new Date();
var year = today.getFullYear();
var month = ("0" + (today.getMonth() + 1)).slice(-2);
var day = ("0" + today.getDate()).slice(-2);
var dateString = year + "-" + month + "-" + day;

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxGroupItem,
    DxTextArea,
    DxPatternRule,
    DxEmailRule,
    DxRequiredRule,
    DxButtonItem,
    DxButtonOptions,
    ModifySymptom,
    ModifyDrugAtc,
    DxEmptyItem,
    PrintSendCard,
    DxLoadPanel,
    DxScrollView
  },
  props: {
    isEditingMode: Boolean,
    isIFMode: Boolean,
    recId: String,
    ptNumber: String,
    formData: Object,
    showPrint: Boolean,
    hideNewbtn: Boolean
  },
  computed: {
    getColCount() {
      let colCount = 0;
      if (this.visibleNewBtn) {
        colCount = 6;
      } else {
        colCount = 5;
      }

      if(this.showPrint){
        colCount = colCount + 1;
      }
      return colCount;
    },
  },
  data() {
    return {
      ADRFormRefName: "ADRForm",
      d2CiGroupRefName: "d2CiGroup",
      d2CrhiGroupRefName: "d2CrhiGroup",
      d2OaiGroupRefName: "d2OaiGroup",
      editorOptionsDateBoxD4: {
        displayFormat: "yyyy-MM-dd",
        dateSerializationFormat: "yyyy-MM-dd",
        useMaskBehavior: true,
        readOnly: true
      },
      editorOptionsDateBox: {
        displayFormat: "yyyy-MM-dd",
        dateSerializationFormat: "yyyy-MM-dd",
        useMaskBehavior: true,
      },
      editorOptionsTextArea: { height: "200px" },
      loading: false,
      btnNameSave: "",
      dataSource: {d1List: [{}]},
      recStatus: userStatus,
      hospitalCode: store.getters.getHospitalCode,
      hospitalName: "",
      toDay: dateString,
      causalityitems: [
        "확실함",
        "상당히 확실함",
        "가능함",
        "가능성 적음",
        "평가곤란"
      ],
      d2TemplateList: [],
      d3TemplateList: [],
      d1List: [{visible: true},
                {visible: false},
                {visible: false},
                {visible: false},
                { visible: false }],
      visibleNewBtn: false,
      visibleAp: false,
      btnToggleApName: "신청인 정보 보기",
      printSendCardRefName: "printSendCard",
      dataSourceLocalOrgStr: "",
      localOrgStrD1CausativeDrug: '',
      localOrgStrD1Symptom: '',
      localOrgStrD2CiGroup: '',
      localOrgStrD2CrhiGroup: '',
      localOrgStrD2OaiGroup: '',
      localOrgStrD2ExpertOpinion: ''

    };
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.dataSource = this.formData;
        if (!this.isEditingMode) {
          this.dataSource.d4InsertTime = this.toDay; 
        }
        this.dataSource.d3HospitalCode = this.hospitalCode;
        this.dataSource.d3HospitalName = this.hospitalName;
      },
    },
  },
  mounted() {
    this.mount();
  },
  methods: {
    mount() {

      this.dataSource = { d1List: [{}] };
      this.visibleNewBtn = false;

      if (this.isEditingMode) {
        this.btnNameSave = "수정";
        this.searchData();
      } else {
        this.btnNameSave = "등록";
        this.dataSource.d4InsertTime = dsu.getFormatDate(new Date());
        this.dataSource.d3HospitalCode = this.hospitalCode;
        dsu.getAxiosOBJGet("/api/ABIF/select", { code: this.hospitalCode })
          .then((res) => {
            let response = res.data;
            if (response.result == "SUCCESS") {
              this.hospitalName = response.data[0].name;

              this.dataSource.d3HospitalName = this.hospitalName;
            } else {
              fn.notifyError(response.msg);
            }
          });
      }
      
      dsu.getAxiosD2TemplateInfo().then((res) => {
        this.d2TemplateList = res.data.data;
      });
      dsu.getAxiosD3TemplateInfo().then((res) => {
        this.d3TemplateList = res.data.data;
        if (!this.isEditingMode) {
          if (this.d3TemplateList.length == 1) {
            this.dataSource.d3Template = this.d3TemplateList[0].templateId;
          }
        }
      });
      if (this.ptNumber) {
        this.dataSource.ptNumber = this.ptNumber;
        this.getPTInfo();
      }
    },
    setIFData(adrDataIF) {
      this.dataSource.ptName = adrDataIF.ptName;
      this.dataSource.ptNumber = adrDataIF.ptNumber;
      this.dataSource.ptRrn = adrDataIF.ptRrn;
      this.dataSource.ptTel = adrDataIF.ptTel;
      this.dataSource.ptEmail = adrDataIF.ptEmail;
      this.dataSource.ptAddress = adrDataIF.ptAddress;
      this.dataSource.apName = adrDataIF.apName;
      this.dataSource.apRelationship = adrDataIF.apRelationship;
      this.dataSource.apRrn = adrDataIF.apRrn;
      this.dataSource.apTel = adrDataIF.apTel;
      this.dataSource.apEmail = adrDataIF.apEmail;

      var adrDataIFd1List = JSON.parse(adrDataIF.d1List);
      for(let i = 0; i < adrDataIFd1List.length; i++){
        this.d1List[i].visible = true;
        adrDataIFd1List[i].d1CausativeDrug = JSON.stringify(adrDataIFd1List[i].d1CausativeDrug);
        adrDataIFd1List[i].d1Symptom = JSON.stringify(adrDataIFd1List[i].d1Symptom);
      }
      this.dataSource.d1List = adrDataIFd1List;

      this.dataSource.d1ManagementNumber = adrDataIF.d1ManagementNumber;
      this.dataSource.d2CurrentStatus = adrDataIF.d2CurrentStatus;
      this.dataSource.d2DrugSideEffectsType = adrDataIF.d2DrugSideEffectsType;
      this.dataSource.d2Symptom = adrDataIF.d2Symptom;
      this.dataSource.d2CiGroup = adrDataIF.d2CiGroup;
      this.dataSource.d2CrhiGroup = adrDataIF.d2CrhiGroup;
      this.dataSource.d2OaiGroup = adrDataIF.d2OaiGroup;
      this.dataSource.d2Causality = adrDataIF.d2Causality;
      this.dataSource.d2Severity = adrDataIF.d2Severity;
      this.dataSource.d2ExpertOpinion = adrDataIF.d2ExpertOpinion;
      this.dataSource.d2ConfTest = adrDataIF.d2ConfTest;

      if(adrDataIF.d4InsertTime){
        this.dataSource.d4InsertTime = adrDataIF.d4InsertTime;
      }
      if(adrDataIF.d4UpdateTime){
        this.dataSource.d4UpdateTime = adrDataIF.d4UpdateTime;
      }
      this.dataSource.reqId = adrDataIF.reqId;
      this.dataSource.status = 'ACTIVE';
    },
    setLocalData(adrDataLocal) {
      this.dataSource.ptName = adrDataLocal.ptName;
      this.dataSource.ptNumber = adrDataLocal.ptNumber;
      this.dataSource.ptRrn = adrDataLocal.ptRrn;
      this.dataSource.ptTel = adrDataLocal.ptTel;
      this.dataSource.ptEmail = adrDataLocal.ptEmail;
      this.dataSource.ptAddress = adrDataLocal.ptAddress;
      this.dataSource.apName = adrDataLocal.apName;
      this.dataSource.apRelationship = adrDataLocal.apRelationship;
      this.dataSource.apRrn = adrDataLocal.apRrn;
      this.dataSource.apTel = adrDataLocal.apTel;
      this.dataSource.apEmail = adrDataLocal.apEmail;
      var adrDataIFd1List = JSON.parse(adrDataLocal.d1List);
      for(let i = 0; i < adrDataIFd1List.length; i++){
        this.d1List[i].visible = true;
      }
      this.dataSource.d1List = adrDataIFd1List;
      this.dataSource.d1ManagementNumber = adrDataLocal.d1ManagementNumber;
      this.dataSource.d2CurrentStatus = adrDataLocal.d2CurrentStatus;
      this.dataSource.d2DrugSideEffectsType = adrDataLocal.d2DrugSideEffectsType;
      this.dataSource.d2Symptom = adrDataLocal.d2Symptom;
      this.dataSource.d2CiGroup = adrDataLocal.d2CiGroup;
      this.dataSource.d2CrhiGroup = adrDataLocal.d2CrhiGroup;
      this.dataSource.d2OaiGroup = adrDataLocal.d2OaiGroup;
      this.dataSource.d2Causality = adrDataLocal.d2Causality;
      this.dataSource.d2Severity = adrDataLocal.d2Severity;
      this.dataSource.d2ExpertOpinion = adrDataLocal.d2ExpertOpinion;
      this.dataSource.status = 'ACTIVE';
      this.dataSource.mD1CausativeDrug = adrDataLocal.mD1CausativeDrug;
      this.dataSource.mD1Symptom = adrDataLocal.mD1Symptom;
      this.dataSource.mD2CiGroup = adrDataLocal.mD2CiGroup;
      this.dataSource.mD2CrhiGroup = adrDataLocal.mD2CrhiGroup;
      this.dataSource.mD2OaiGroup = adrDataLocal.mD2OaiGroup;
      this.dataSource.mD2ExpertOpinion = adrDataLocal.mD2ExpertOpinion;
      this.localOrgStrD1CausativeDrug  = JSON.stringify(this.dataSource.d1List.map(item => JSON.parse(item.d1CausativeDrug)).flat());
      this.localOrgStrD1Symptom  = JSON.stringify(this.dataSource.d1List.map(item => JSON.parse(item.d1Symptom)).flat());
      this.localOrgStrD2CiGroup = JSON.stringify(this.dataSource.d2CiGroup);
      this.localOrgStrD2CrhiGroup = JSON.stringify(this.dataSource.d2CrhiGroup); 
      this.localOrgStrD2OaiGroup = JSON.stringify(this.dataSource.d2OaiGroup);
      this.localOrgStrD2ExpertOpinion = JSON.stringify(this.dataSource.d2ExpertOpinion);
    },
    searchData() {
      dsu
        .getAxiosOBJGet("/api/MRPM/selectADRData", { recId: this.recId })
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            dsu.getAxiosOBJGet("/api/MRPM/selectADRDataD1", { recId: this.recId })
            .then((res) => {
              let responseD1 = res.data;
              if (responseD1.result == "SUCCESS") {
                for (let i = 0; i < responseD1.data.length; i++) {
                  this.d1List[i].visible = true;
                }
                response.data[0].d1List = responseD1.data;
                this.dataSource = response.data[0]; 
              } else {
                fn.notifyError(responseD1.msg);
              }
            });
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    save() {
      const ADRForm = this.$refs[this.ADRFormRefName].instance;
      var params = JSON.parse(JSON.stringify(this.dataSource));
      params.d2Template = "";
      let validationResult = ADRForm.validate();
      
      for (let i = 0; i < params.d1List.length; i++){

        if (params.d1List[i].d1OccurenceDate.indexOf('-') === -1) {
            // if 데이터중 - 없이 들어올떄 ?
            const year = params.d1List[i].d1OccurenceDate.substring(0, 4);
            const month = params.d1List[i].d1OccurenceDate.substring(4, 6);
            const day = params.d1List[i].d1OccurenceDate.substring(6, 8);
            const formattedDate = `${year}-${month}-${day}`;
          params.d1List[i].d1OccurenceDate = formattedDate;
        }

        if (!params.d1List[i].d1CausativeDrug) {
          fn.notifyError("원인약물, 약물이상사례(개별사례) 필수 입력");
          return;
        }

        if (!params.d1List[i].d1Symptom) {
          fn.notifyError("증상, 약물이상사례(개별사례) 필수 입력");
          return;
        }
      }

      if (validationResult.status != "valid") {
        fn.notifyError("입력 데이터 확인 필요");
        return;
      }

      this.loading = true;
      params.d1List = JSON.stringify(params.d1List);

      if(this.isIFMode && params.d1ManagementNumber){
        var paramExists = {};
        paramExists.ptNumber = params.ptNumber;
        paramExists.d1ManagementNumber = params.d1ManagementNumber;
        paramExists.hospitalCode = params.d3HospitalCode;
        dsu.getAxiosOBJGet("/api/MRPD/selectExistsADRData", paramExists).then((res) => {
          let responseExists = res.data;
          if (responseExists.result == "SUCCESS") {
            if (responseExists.data) {
              params.recId = responseExists.data;
              fn.alert("병원등록번호, 원내이상사례 관리번호 기준 등록된 데이터가 있어 해당 데이터에 업데이트 됩니다. (Record ID : " + params.recId + ")", "확인").then(() => {
                this.saveADRData(params);
              });
            } else {
              this.saveADRData(params);
            }
          } else {
            fn.notifyError(responseExists.msg);
            this.loading = false;
            return;
          }
        });
      } else {
        this.saveADRData(params);
      }
    },
    saveADRData(params) {
      dsu.getAxiosOBJPost("/api/MRPD/insertADRData", params).then((res) => {
        let response = res.data;
        this.loading = false;
        if (response.result == "SUCCESS") {
          fn.notifySuccess(this.btnNameSave + " 완료");
          if (this.isEditingMode || this.isIFMode) {
            this.$emit("refreshDataParent");
          } else {
            this.btnNameSave = "수정";
            this.dataSource.recId = response.data;
            this.dataSource.status = 'ACTIVE';
            this.visibleNewBtn = !this.hideNewbtn;
            if (this.ptNumber || this.hideNewbtn){
              this.$emit("refreshDataParent");
            }
          }
        } else {
          fn.notifyError(response.msg);
        }
        this.loading = false;
      });
    },
    setD1CausativeDrug(d1CausativeDrug, index) {
      if (d1CausativeDrug.length == 0) {
        delete this.dataSource.d1List[index].d1CausativeDrug;
      }else{
        this.dataSource.d1List[index].d1CausativeDrug = JSON.stringify(d1CausativeDrug);
      }
    },
    getD1CausativeDrug(callback, index){
      if(this.dataSource.d1List.length > index)
      {
        if (this.dataSource.d1List[index].d1CausativeDrug) {
          callback(this.dataSource.d1List[index].d1CausativeDrug);
        }
      }
    },
    getD1CausativeDrugAll(callback)
    {
      var result = JSON.stringify(this.dataSource.d1List.reduce((acc, item) => {
        var result = null; 
        if(item.d1CausativeDrug)
        {
          result = JSON.parse(item.d1CausativeDrug);
        }
        return acc.concat(result);
      }, []));
      callback(result);
    },
    setD1Symptom(d1Symptom, index) {
      if (d1Symptom.length == 0) {
        delete this.dataSource.d1List[index].d1Symptom;
      }else{
        this.dataSource.d1List[index].d1Symptom = JSON.stringify(d1Symptom);
      }
    },
    getD1Symptom(callback, index){
      if(this.dataSource.d1List.length > index)
      {
        if (this.dataSource.d1List[index].d1Symptom) {
          callback(this.dataSource.d1List[index].d1Symptom);
        }
      }
    },
    getD1SymptomAll(callback) {
      var result = JSON.stringify(this.dataSource.d1List.reduce((acc, item) => {
      var result = null; 
        if(item.d1Symptom)
        {
          result = JSON.parse(item.d1Symptom);
          return acc.concat(result);
        }
      }, []));

      callback(result);
    },
    setD2Symptom(d2Symptom) {
      this.dataSource.d2Symptom = JSON.stringify(d2Symptom);
    },
    getD2Symptom(callback){
      if (this.dataSource.d2Symptom) {
        callback(this.dataSource.d2Symptom);
      }
    },
    setD2CiGroup(d2CiGroup) {
      this.dataSource.d2CiGroup = JSON.stringify(d2CiGroup);
    },
    getD2CiGroup(callback){
      if (this.dataSource.d2CiGroup) {
        callback(this.dataSource.d2CiGroup);
      }
    },
    setD2CrhiGroup(d2CrhiGroup) {
      this.dataSource.d2CrhiGroup = JSON.stringify(d2CrhiGroup);
    },
    getD2CrhiGroup(callback){
      if (this.dataSource.d2CrhiGroup) {
        callback(this.dataSource.d2CrhiGroup);
      }
    },
    setD2OaiGroup(d2OaiGroup) {
      this.dataSource.d2OaiGroup = JSON.stringify(d2OaiGroup);
    },
    getD2OaiGroup(callback){
      if (this.dataSource.d2OaiGroup) {
        callback(this.dataSource.d2OaiGroup);
      }
    },
    onValueChangedD2TemplateId(e) {
      if (!e.value) {
        return;
      }
      
      var d1CausativeDrug = JSON.stringify(this.dataSource.d1List.reduce((acc, item) => {
      var result = null; 
        if(item.d1CausativeDrug){
          result = JSON.parse(item.d1CausativeDrug);
          return acc.concat(result);
        }
      }, []));

      if (!d1CausativeDrug) {
        d1CausativeDrug = '[]';
      }

      const templateInfo = this.d2TemplateList.find(
        (item) => item.templateId === e.value
      );

      this.setTemplateDrugInfo(
        templateInfo.d2CiGroupInputType,
        "d2CiGroup",
        templateInfo.d2CiGroup,
        d1CausativeDrug
      );

      this.setTemplateDrugInfo(
        templateInfo.d2CrhiGroupInputType,
        "d2CrhiGroup",
        templateInfo.d2CrhiGroup,
        d1CausativeDrug
      );

      this.setTemplateDrugInfo(
        templateInfo.d2OaiGroupInputType,
        "d2OaiGroup",
        templateInfo.d2OaiGroup,
        d1CausativeDrug
      );
      let convD2ExpertOpinion = templateInfo.d2ExpertOpinion;
      let d2TemplateD1CausativeDrug = '';
      let d2TemplateD1Symptom = '';

      try {
        d2TemplateD1CausativeDrug=[
          ...new Set(
            this.dataSource.d1List
              .flatMap((d1) => JSON.parse(d1.d1CausativeDrug).map((item) => item.drugName))
          )
        ].join(", ");
      }catch(e){
        console.log(e);
      }

      try{
        d2TemplateD1Symptom=[
          ...new Set(
            this.dataSource.d1List
              .flatMap((d1) => JSON.parse(d1.d1Symptom).map((item) => item.symptomNameKor))
          )
        ].join(", ");
      }catch(e){
        console.log(e);
      }
      
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d1CausativeDrug\}/g, d2TemplateD1CausativeDrug);
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d1Symptom\}/g, d2TemplateD1Symptom);
      
      let d2TemplateD2CiGroup = '';
      if (this.dataSource.d2CiGroup) {
        d2TemplateD2CiGroup = JSON.parse(this.dataSource.d2CiGroup).map((item) => item.drugName).join(", ");  
      }
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d2CiGroup\}/g, d2TemplateD2CiGroup);

      let d2TemplateD2CrhiGroup = '';
      if (this.dataSource.d2CrhiGroup) {
        d2TemplateD2CrhiGroup = JSON.parse(this.dataSource.d2CrhiGroup).map((item) => item.drugName).join(", ");
      
      }
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d2CrhiGroup\}/g, d2TemplateD2CrhiGroup);
      
      let d2TemplateD2OaiGroup = '';
      if (this.dataSource.d2OaiGroup) {
        d2TemplateD2OaiGroup = JSON.parse(this.dataSource.d2OaiGroup).map((item) => item.drugName).join(", ");  
      }
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d2OaiGroup\}/g, d2TemplateD2OaiGroup);

      let d2TemplateD2Symptom = '';
      if (this.dataSource.d2Symptom) {
        d2TemplateD2Symptom = JSON.parse(this.dataSource.d2Symptom).map((item) => item.symptomNameKor).join(", ");      
      }
      convD2ExpertOpinion = convD2ExpertOpinion.replace(/\{d2Symptom\}/g, d2TemplateD2Symptom);

      this.dataSource.d2ExpertOpinion = convD2ExpertOpinion;
    },
    setTemplateDrugInfo(inputType, target, drugData, d1CausativeDrug) {
alert(JSON.stringify(d1CausativeDrug));
      if (inputType == "TYPE1") {
        this.dataSource[target] = drugData;
        this.$refs[target].setTargetList(this.dataSource[target]);
      } else if (inputType == "TYPE2") {
        this.dataSource[target] = d1CausativeDrug;
        this.$refs[target].setTargetList(this.dataSource[target]);
      } else if (inputType == "TYPE3" || inputType == "TYPE4") {
        var params = {};
        if (inputType == "TYPE3") {
          params.depth = "1";
        } else if (inputType == "TYPE4") {
          params.depth = "2";
        }
        params.searchDrugCode = d1CausativeDrug;
        dsu
          .getAxiosOBJPost("/api/MD2T/selectTemplateAtcInfo", params)
          .then((res) => {
            let response = res.data;
            if (response.result == "SUCCESS") {
              this.dataSource[target] = JSON.stringify(response.data); 
              this.$refs[target].setTargetList(this.dataSource[target]);
            } else {
              fn.notifyError(response.msg);
            }
          });
      }
    },
    onValueChangedD3TemplateId(e) {
      dsu.getAxiosD3TemplateInfo({ templateId: e.value }).then((res) => {
        var data = res.data.data[0];
        this.dataSource.d3FinalEvaluator = data.d3FinalEvaluator;
        this.dataSource.d3MedicalDepartment = data.d3MedicalDepartment;
        this.dataSource.d3EvaluationDepartment = data.d3EvaluationDepartment;
        this.dataSource.d3ManagerTel = data.d3ManagerTel;
        this.dataSource.d3Email = data.d3Email;
      });
    },
    getPTInfo() {
      if (!this.isEditingMode) {
        var params = {};
        params.ptNumber = this.dataSource.ptNumber;
        dsu
          .getAxiosOBJGet("/api/MVUR/selectPtInfo", params)
          .then((res) => {
            let response = res.data;
            if (response.result == "SUCCESS") {
                let data = response.data[0];
                this.dataSource.ptName = data.ptName;
                this.dataSource.ptRrn = data.ptRrn;
                this.dataSource.ptTel = data.ptTel;
                this.dataSource.ptEmail = data.ptEmail;
                this.dataSource.ptAddress = data.ptAddress;
            } else {
              fn.notifyError(response.msg);
            }
          });
      }
    },
    addD1() {
      for(let i = 1; i < this.d1List.length; i++) {
        if(!this.d1List[i].visible) {
          this.d1List[i].visible = true;
          this.dataSource.d1List.push({});
          return;
        }
      }
      fn.notifyError("개별 사례는 최대 " + this.d1List.length + "개 까지 추가 가능합니다.");
    },
    removeD1(index) {
      if (this.dataSource.d1List.length == 1) {
        fn.notifyError("최소 1개 이상의 개별 사례는 등록해야 합니다.");
        return;
      }
      this.d1List[this.dataSource.d1List.length - 1].visible = false;
      this.dataSource.d1List.splice(index, 1);
    },
    reloadForm() {
      this.mount();
    },
    toggleApName(){
      this.visibleAp = !this.visibleAp;
      if(this.visibleAp){
        this.btnToggleApName = "신청인 정보 숨기기";
      }else{
        this.btnToggleApName = "신청인 정보 보기";
    }
    },
    printCard() {
      var params = {};
      const printSendCard = this.$refs[this.printSendCardRefName];
      if (this.dataSource.recId) {
        params.printMethod = "NORMAL";
        params.targetRecId = this.dataSource.recId; 
        printSendCard.show(params);
      } else {
        params = JSON.parse(JSON.stringify(this.dataSource));
        if (!params.ptName) {
          fn.notifyError("성명, 등록 희망자 필수 입력");
          return;
        }

        if (!params.ptNumber) {
          fn.notifyError("병원등록번호, 등록 희망자 필수 입력");
          return;
        }

        if (!params.ptRrn) {
          fn.notifyError("생년월일, 등록 희망자 필수 입력");
          return;
        }
        
        for (let i = 0; i < params.d1List.length; i++){
          if (!params.d1List[i].d1OccurenceDate) {
            fn.notifyError("발생일, 약물이상사례(개별사례) 필수 입력");
            return;
          }

          if (!params.d1List[i].d1CausativeDrug) {
            fn.notifyError("원인약물, 약물이상사례(개별사례) 필수 입력");
            return;
          }

          if (!params.d1List[i].d1Symptom) {
            fn.notifyError("증상, 약물이상사례(개별사례) 필수 입력");
            return;
          }
        }

        var ptInfo = {};
        ptInfo.ptName = params.ptName;
        ptInfo.ptNumber = params.ptNumber;
        ptInfo.ptBirthDay = params.ptRrn.substring(0, 4) + '.' + params.ptRrn.substring(4, 6) + '.' + params.ptRrn.substring(6, 8);
        ptInfo.ptTel = params.ptTel;
        params.ptName = '';
        params.ptNumber = '';
        params.ptRrn = '';
        params.ptTel = '';
        params.ptEmail = '';
        params.ptAddress = '';
        params.apName = '';
        params.apNumber = '';
        params.apRrn = '';
        params.apTel = '';
        params.apEmail = '';

        params.d1CausativeDrug = JSON.stringify(params.d1List.map(item => JSON.parse(item.d1CausativeDrug)).flat());
        params.d1Symptom = JSON.stringify(params.d1List.map(item => JSON.parse(item.d1Symptom)).flat());
        params.d1OccurenceDate = params.d1List.map(item => item.d1OccurenceDate).flat();
        params.d1OccurenceDate = params.d1OccurenceDate.reduce((earliest, current) => {
          return (new Date(current) < new Date(earliest)) ? current : earliest;
        });
        params.d1List = JSON.stringify(params.d1List);

        const strD2CiGroup = JSON.stringify(this.dataSource.d2CiGroup);
        const strD2CrhiGroup = JSON.stringify(this.dataSource.d2CrhiGroup); 
        const strD2OaiGroup = JSON.stringify(this.dataSource.d2OaiGroup);
        const strD2ExpertOpinion = JSON.stringify(this.dataSource.d2ExpertOpinion);

        if (this.localOrgStrD1CausativeDrug != params.d1CausativeDrug) {
          params.mD1CausativeDrug = '';
        }
        if (this.localOrgStrD2CiGroup != params.d1Symptom) {
          params.mD1Symptom = '';
        }
        if (this.localOrgStrD2CiGroup != strD2CiGroup) {
          params.mD2CiGroup = '';
        }
        if (this.localOrgStrD2CrhiGroup != strD2CrhiGroup) {
          params.mD2CrhiGroup = '';
        }
        if (this.localOrgStrD2OaiGroup != strD2OaiGroup) {
          params.mD2OaiGroup = '';
        }
        if (this.localOrgStrD2ExpertOpinion != strD2ExpertOpinion) {
          params.mD2ExpertOpinion = '';
        }

        this.loading = true;
        dsu.getAxiosOBJPost("/api/MRPD/insertADRDataTemp", params).then((res) => {
          let response = res.data;
          this.loading = false;
          if (response.result == "SUCCESS") {
            var paramsTemp = {};
            paramsTemp.printMethod = 'TEMP';
            paramsTemp.targetRecId = response.data;
            printSendCard.show(paramsTemp, ptInfo);
          } else {
            fn.notifyError(response.msg);
          }
          this.loading = false;
        });
      }
    }
  },
};
</script>

<style lang="scss">
  .labelRed {
    color: red;
    font-weight: bold;
  }

  .labelOrange {
    color: #ec6e2d;
    font-weight: bold;
  }

  .labelGreen {
    color: #4fad59;
    font-weight: bold;
  }

  .labelGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

    .icon {
      height: 30px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .form-group {
    border-radius: 2px;
    border: 1px solid rgb(208, 207, 207); 
  }

  .dxform-container {
    position: relative;
    height: 100%; /* 전체 뷰포트 높이 */
    display: flex;
    flex-direction: column;
  }

  .scrollable-content {
    overflow-y: auto;
    flex-grow: 1;
  }

  .fixed-button {
    position: sticky;
    padding-top: 20px;
    background: white; /* 버튼이 다른 내용과 겹치지 않게 배경색 설정 */
    box-shadow: 0 -1px 0 rgba(43, 43, 43, 0.3);
  }
  .adrDataForm{
    padding-bottom: 20px;
  }
</style>
